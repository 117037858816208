import { all, put, call } from 'redux-saga/effects'
import getPortfolioData from 'services/portfolio'

export function* GET_DATA() {
  const portfolioData = yield call(getPortfolioData)
  yield put({
    type: 'portfolio/SET_STATE',
    payload: {
      portfolioData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
