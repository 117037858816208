export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Navigation',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'fe fe-home',
      url: '/home',
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-activity',
      url: '/dashboard',
      children: [
        {
          title: 'Corporate Model',
          key: 'graphs-corporate',
          children: [
            {
              title: 'Audit Management',
              key: 'graphs-corporate-company-profile',
              url: '/dashboard/corporate/audit-management',
            },
          ],
        },
        {
          title: 'Bank Model',
          key: 'graphs-bank',
          children: [
            {
              title: 'Audit Management',
              key: 'graphs-bank-company-profile',
              url: '/dashboard/bank/audit-management',
            },
          ],
        },
        {
          title: 'Real Estate Model',
          key: 'graphs-real-estate',
          children: [
            {
              title: 'Audit Management',
              key: 'graphs-real-estate-company-profile',
              url: '/dashboard/real-estate/saf/audit-management',
            },
          ],
        },
        {
          title: 'Pending approval',
          key: 'pending-approval',
          url: '/dashboard/management/pending-approval',
        },
        {
          title: 'Notifications',
          key: 'notifications',
          url: '/dashboard/management/notifications',
        },
      ],
    },
    {
      category: true,
      title: 'Analytics',
    },
    {
      title: 'Models',
      key: 'models',
      icon: 'fe fe-search',
      // url: '/discovery',
      children: [
        {
          title: 'Corporate Model',
          key: 'model-corporate',
          url: '/model/corporate',
          children: [
            {
              title: 'PD',
              key: 'model-corporate-pd',
              url: '/model/corporate/pd',
              children: [
                {
                  title: 'Portfolio',
                  key: 'model-corporate-pd-audit',
                  url: '/model/corporate/pd/audit',
                }
              ],
            },
            {
              title: 'LGD',
              key: 'model-corporate-lgd',
              url: '/model/corporate/lgd',
              children: [
                {
                  title: 'Portfolio',
                  key: 'model-corporate-lgd-audit',
                  url: '/model/corporate/lgd/audit',
                }
              ],
            }
          ],
        },
        {
          title: 'Bank Model',
          key: 'model-bank',
          url: '/model/bank',
          children: [
            {
              title: 'PD',
              key: 'model-bank-pd',
              url: '/model/bank/pd',
              children: [
                {
                  title: 'Portfolio',
                  key: 'model-bank-pd-audit',
                  url: '/model/bank/pd/audit',
                }
              ],
            },
          ],
        },
        {
          title: 'Real Estate',
          key: 'model-real-estate',
          url: '/model/real-estate',
          children: [
            {
              title: 'Single Asset Finance',
              key: 'model-real-estate-saf',
              url: '/model/real-estate/saf',
              children: [
                {
                  title: 'PD',
                  key: 'model-real-estate-pd',
                  url: '/model/real-estate/saf/pd',
                  children: [
                    {
                      title: 'Portfolio',
                      key: 'model-real-estate-saf-pd-audit',
                      url: '/model/real-estate/saf/pd/audit',
                    }
                  ],
                },
                {
                  title: 'LGD',
                  key: 'model-real-estate-lgd',
                  url: '/model/real-estate/saf/lgd',
                  children: [
                    {
                      title: 'Portfolio',
                      key: 'model-real-estate-saf-lgd-audit',
                      url: '/model/real-estate/saf/lgd/audit',
                    }
                  ],
                },
              ],
            },
          ],
        }
      ],
    },
    // {
    //   title: 'Portfolio',
    //   key: 'portfolio',
    //   icon: 'fe fe-search',
    //   // url: '/discovery',
    //   children: [
    //     {
    //       title: 'Corporate Companies',
    //       key: 'portfolio-corporate',
    //       url: '/portfolio/corporate',
    //       children: [
    //         {
    //           title: 'Audit',
    //           key: 'portfolio-corporate-audit',
    //           url: '/portfolio/corporate/audit',
    //         }
    //       ],
    //     },
    //     {
    //       title: 'Banks',
    //       key: 'portfolio-bank',
    //       url: '/portfolio/bank',
    //       children: [
    //         {
    //           title: 'Audit',
    //           key: 'portfolio-bank-audit',
    //           url: '/portfolio/bank/audit',
    //         }
    //       ],
    //     }
    //   ],
    // },
    {
      category: true,
      title: 'Documentation',
    },
    {
      title: 'Knowledgebase',
      key: 'Knowledgebase',
      icon: 'fe fe-home',
      url: '/knowledgebase',
    },
    {
      category: true,
      title: 'Toolkit',
    },
    {
      title: 'Tools',
      key: 'tools',
      icon: 'fe fe-home',
      url: '/tools',
    },
    {
      title: 'Help',
      key: 'help',
      icon: 'fe fe-home',
      url: '/help',
    },
    {
      category: true,
      title: 'Admin',
    },
    {
      title: 'Admin',
      key: 'admin-panel',
      icon: 'fe fe-home',
      url: '/admin-panel',
    },
  ]
}
