import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useAuth0 } from "@auth0/auth0-react";
import { connect, useDispatch } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Custom
  {
    path: '/dashboard/test',
    Component: lazy(() => import('pages/charts/chartjs')),
    exact: true,
  },
  // Home
  {
    path: '/home',
    Component: lazy(() => import('pages/custom/home/landing')),
    exact: true,
  },
  // Dashboard
  // Corporate Profile Dashboard
  {
    path: '/dashboard/corporate/audit-management',
    Component: lazy(() => import('pages/custom/dashboard/corporate/company-profile')),
    exact: true,
  },
  {
    path: '/dashboard/corporate/audit-management/:entityId',
    Component: lazy(() => import('pages/custom/dashboard/corporate/company-profile/company-details')),
    exact: true,
  },
  // Bank Profile Dashboard
  {
    path: '/dashboard/bank/audit-management',
    Component: lazy(() => import('pages/custom/dashboard/bank/company-profile')),
    exact: true,
  },
  {
    path: '/dashboard/bank/audit-management/:entityId',
    Component: lazy(() => import('pages/custom/dashboard/bank/company-profile/company-details')),
    exact: true,
  },
  // Real Estate Profile Dashboard
  {
    path: '/dashboard/real-estate/saf/audit-management',
    Component: lazy(() => import('pages/custom/dashboard/real-estate/saf/company-profile')),
    exact: true,
  },
  {
    path: '/dashboard/real-estate/saf/audit-management/:entityId',
    Component: lazy(() => import('pages/custom/dashboard/real-estate/saf/company-profile/company-details')),
    exact: true,
  },
  {
    path: '/dashboard/management/pending-approval',
    Component: lazy(() => import('pages/custom/dashboard/management/pending-approval')),
    exact: true,
  },

  // Models Audit
  // PD
  {
    path: '/model/corporate/pd/audit',
    Component: lazy(() => import('pages/custom/models/corporate/pd/audit')),
    exact: true,
  },
  {
    path: '/model/corporate/pd/audit/:portfolioId',
    Component: lazy(() => import('pages/custom/models/corporate/pd/audit/portfolio-details')),
    exact: true,
  },

  {
    path: '/model/corporate/pd/assessment/:assessmentId',
    Component: lazy(() => import('pages/custom/models/corporate/pd/assessment')),
    exact: true,
  },
  // LGD
  {
    path: '/model/corporate/lgd/audit',
    Component: lazy(() => import('pages/custom/models/corporate/lgd/audit')),
    exact: true,
  },
  {
    path: '/model/corporate/lgd/audit/:portfolioId',
    Component: lazy(() => import('pages/custom/models/corporate/lgd/audit/portfolio-details')),
    exact: true,
  },
  {
    path: '/model/corporate/lgd/assessment/:assessmentId',
    Component: lazy(() => import('pages/custom/models/corporate/lgd/assessment')),
    exact: true,
  },
  // Bank PD
  {
    path: '/model/bank/pd/audit',
    Component: lazy(() => import('pages/custom/models/bank/pd/audit')),
    exact: true,
  },
  {
    path: '/model/bank/pd/audit/:portfolioId',
    Component: lazy(() => import('pages/custom/models/bank/pd/audit/portfolio-details')),
    exact: true,
  },

  {
    path: '/model/bank/pd/assessment/:assessmentId',
    Component: lazy(() => import('pages/custom/models/bank/pd/assessment')),
    exact: true,
  },
  // Real Estate PD Single Asset Finance
  {
    path: '/model/real-estate/saf/pd/audit',
    Component: lazy(() => import('pages/custom/models/real-estate/saf/pd/audit')),
    exact: true,
  },
  {
    path: '/model/real-estate/saf/pd/audit/:portfolioId',
    Component: lazy(() => import('pages/custom/models/real-estate/saf/pd/audit/portfolio-details')),
    exact: true,
  },

  {
    path: '/model/real-estate/saf/pd/assessment/:assessmentId',
    Component: lazy(() => import('pages/custom/models/real-estate/saf/pd/assessment')),
    exact: true,
  },
  // Real Estate LGD Single Asset Finance
  {
    path: '/model/real-estate/saf/lgd/audit',
    Component: lazy(() => import('pages/custom/models/real-estate/saf/lgd/audit')),
    exact: true,
  },
  {
    path: '/model/real-estate/saf/lgd/audit/:portfolioId',
    Component: lazy(() => import('pages/custom/models/real-estate/saf/lgd/audit/portfolio-details')),
    exact: true,
  },

  {
    path: '/model/real-estate/saf/lgd/assessment/:assessmentId',
    Component: lazy(() => import('pages/custom/models/real-estate/saf/lgd/assessment')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch()

  console.log(user)
  dispatch({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      authorized: isAuthenticated,
      id: user?.sub,
      name: user?.name,
      userId: user?.['https://app.react.risk-enterprise.com/app_metadata'].userId || "",
      role: user?.['https://app.react.risk-enterprise.com/app_metadata'].role || "",
      team: user?.['https://app.react.risk-enterprise.com/app_metadata'].team || "",
      workspace: user?.['https://app.react.risk-enterprise.com/app_metadata'].workspace || "",
      email: user?.email,
      // avatar: user.avatar,
    },
  })

  if (isLoading && !isAuthenticated) {
    return null
  }

  if (!isLoading && !isAuthenticated) {
    return loginWithRedirect()
  }

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/home" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
